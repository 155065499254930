export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const INC_COUNT = "INC_COUNT";
export const DEC_COUNT = "DEC_COUNT";
export const SET_COLORS = "SET_COLORS";
export const REQUEST_UPGRADE_CART_ITEMS = "REQUEST_UPGRADE_CART_ITEMS";
export const UPGRADE_CART_ITEMS = "UPGRADE_CART_ITEMS";
export const ERROR_UPGRADE_CART_ITEMS = "ERROR_UPGRADE_CART_ITEMS";
export const DELETE_DEPRECATED_ITEM = "DELETE_DEPRECATED_ITEM";
export const RESET_CART_ITEMS = "RESET_CART_ITEMS";
